<template>
  <div class="case-conent">
    <div class="hd">订单</div>
    <!--搜索结果-->
    <div class="table-content-search">
      <div class="search">
        <div class="tab-tilte">
          <li @click="orderObjA()" :class="{active:orderCur==0}">充值订单</li>
          <li @click="orderObjB()" :class="{active:orderCur==1}">课时购买记录</li>
          <li @click="orderObjC()" :class="{active:orderCur==2}">整本书购买记录</li>
        </div>
      </div>
      <div class="table">
        <div class="tab-content">
          <div class="rech" v-show="orderCur==0">
            <span v-if="queryOrderListData.length>0">
              <el-table
                  border
                  :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                  :data="queryOrderListData"
              >
              <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
              <el-table-column prop="orderNo" label="订单号"  header-align="center"></el-table-column>
              <el-table-column prop="studentName" label="学生姓名" header-align="center"></el-table-column>
              <el-table-column prop="payment" label="充值金额" header-align="center" width="100"></el-table-column>
              <el-table-column prop="payType" label="充值方式" header-align="center" width="100">
              </el-table-column>
              <el-table-column prop="status" label="状态" header-align="center" width="90"></el-table-column>
              <el-table-column prop="payTime"  label="充值时间" header-align="center" width="200"></el-table-column>

              <el-table-column prop="" label="操作" header-align="center" width="120">
                <template #default="scope">
                  <el-button @click="rechargeObj(scope)" type="primary" size="mini" plain>详情</el-button>
                </template>
              </el-table-column>

            </el-table>
            </span>
            <span v-else>
              <div class="kong">
                <img src="../../assets/images/kong.png">
                <p>暂无查询结果！</p>
              </div>
        </span>

          </div>
          <div class="rech" v-show="orderCur==1">
            <span v-if="BuyListData.length>0">
              <el-table
                  border
                  :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                  :data="BuyListData"
              >
              <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
              <el-table-column prop="bookName" label="图书名称" header-align="center" ></el-table-column>
              <el-table-column prop="folderName" label="课时名称" header-align="center" ></el-table-column>
                <el-table-column prop="subjectName" label="学科"  header-align="center" width="80"></el-table-column>
              <el-table-column prop="" label="购买类型" header-align="center" width="120">
                <template #default="">
                  课时
                </template>
              </el-table-column>
              <el-table-column prop="payment" label="购买金额" header-align="center" width="120"></el-table-column>
              <el-table-column prop="tradeTime" label="购买时间" header-align="center" width="200"></el-table-column>
            </el-table>
            </span>
            <span v-else>
              <div class="kong">
                <img src="../../assets/images/kong.png">
                <p>暂无查询结果！</p>
              </div>
        </span>

          </div>
          <div class="rech" v-show="orderCur==2">
            <span v-if="WholeByListData.length>0">
              <el-table
                  border
                  :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                  :data="WholeByListData"
              >
              <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>

              <el-table-column prop="bookName" label="图书名称" header-align="center" ></el-table-column>
<el-table-column prop="subjectName" label="学科"  header-align="center" width="80"></el-table-column>
                <el-table-column prop="" label="购买类型" header-align="center" width="120">
                <span class="textlog1">整书</span>
              </el-table-column>
              <el-table-column prop="payment" label="购买金额" header-align="center" width="150"></el-table-column>
              <el-table-column prop="tradeTime" label="购买时间" header-align="center" width="200"></el-table-column>

            </el-table>
            </span>
            <span v-else>
              <div class="kong">
                <img src="../../assets/images/kong.png">
                <p>暂无查询结果！</p>
              </div>
            </span>

          </div>
        </div>
      </div>
      <div class="page" >
        <div class="pag-box" v-if="queryOrderListData !=0&&orderCur==0">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChangeA"
              @current-change="handleCurrentChangeA"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
        <div class="pag-box" v-if="BuyListData !=''&&orderCur==1">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChangeB"
              @current-change="handleCurrentChangeB"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
        <div class="pag-box" v-if="WholeByListData !=''&&orderCur==2">
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChangeC"
              @current-change="handleCurrentChangeC"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 充值订单详情弹框-->
    <el-dialog :visible.sync="rechargeDialog" :modal-append-to-body='false' :close-on-click-modal="false" title="充值订单详情" width="800px" left>
      <div class="dialogOrder">
        <div class="dialogBody">
          <div class="lf">商品名称：</div>
          <div class="rg">学历案充值</div>
        </div>
        <div class="dialogBody">
          <div class="lf">订单号：</div>
          <div class="rg">{{OrderIndorData.orderNo}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">学生姓名：</div>
          <div class="rg">{{OrderIndorData.studentName}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值金额：</div>
          <div class="rg">{{OrderIndorData.payment}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值方式：</div>
          <div class="rg">
            <span>{{OrderIndorData.payType}}</span>
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">状态：</div>
          <div class="rg">
            <span>{{OrderIndorData.status}}</span>
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值时间：</div>
          <div class="rg">
            {{ dateTime(OrderIndorData.create_time) }}
          </div>
        </div>

      </div>
      <div class="dialogFooter">
        <el-button @click="closRechargeObj()" round style="border: 1px solid #1A86FF; background-color: #1A86FF; color: #fff" type="primary">确定</el-button>
      </div>
    </el-dialog>

  </div>

</template>


<script>
import moment from 'moment';
import {getParqueryPayListObj,getParPayDetailObj,getParBuyListObj,getParBookBuyListObj} from '@/api/parent'   //路径
export default {
  name: '',
  data() {
    return {
      userInfo:{

      },
      pageSize:null,
      pageCurrent:null,
      total:null,
      queryOrderListData:[],//充值订单列表
      BuyListData:[],//课时购买订单列表
      WholeByListData:[],//整本购买列表
      d1:[],
      d2:[],
      totalMoney:0,//订单总金额
      orderCur:false,
      OrderIndorData:[],
      listData:[
        {
          sort:1,
          orderNumber:'GR202109300001',
          stuName:'张三',
          rechargeType:'按需充值',
          originalPrice:2356,
          rechargeAmount:1000,
          discount:7,
          rechargeMode:'支付宝',
          state:1,
          time:'2021-10-12 15:38:45'
        }
      ],
      rechargeDialog:false,
      buylistData:[
        {
          sort:1,
          subject:'语文',
          bookName:'GR202109300001',
          chapter:'第一单元 一 《合欢树》',
          buyType:1,
          purchaseAmount:2356,
          purchaseMethod:1,
          state:1,
          time:'2021-10-12 15:38:45'
        }
      ],
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag=this.$route.query.clickFlag;
    this.orderCur=this.$route.query.orderCur;
    this.par = this.$route.query.par;

    this.queryOrListObj()
    this.queryBuyList()
    this.WholeByList()
  },
  methods: {
    orderObjA() {
      this.orderCur=0
      this.queryOrListObj()
    },
    orderObjB() {
      this.orderCur=1
      this.queryBuyList()
    },
    orderObjC() {
      this.orderCur=2
      this.WholeByList()
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    //跳转到目录
    toCatalogue(scope) {
      this.$router.push({
        path: "/mainb/catalogue",
        query: {
          EducationCase: 1,
          clickFlag:0,
          //bookId: 55,
          bookId: scope.row.book_id,
          //totalPrice: scope.totalPrice,
          // totalDiscount: scope.totalDiscount,
        }
      });
    },
    // 去详情
    tosDetails(scope) {
      this.$router.push({
        path: "/mainb/details",
        query: {
          EducationCase: scope.EducationCase,
          clickFlag: scope.row.clickFlag,
          bookId: scope.row.bookId,
          folderId: scope.row.folderId,
          id: scope.row.id,
        }
      });
    },
    //充值详情弹框
    rechargeObj(scope) {
      this.rechargeDialog=true
      let obj = {
        orderNo: scope.row.orderNo,
      }
      getParPayDetailObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        // this.picList = res.data.imgs
        this.OrderIndorData = res.data


      })
    },
    closRechargeObj() {
      this.rechargeDialog=false
      console.log('828822')
    },
    //购买详情弹框
    buyObj(scope) {
      this.d2= scope.row
      this.buyDialog=true
    },
    closbuyObj() {
      this.buyDialog=false
    },

    //充值订单列表
    queryOrListObj() {
      let params = {
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      getParqueryPayListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.code = res.code;
          this.queryOrderListData = res.data.records
          this.pageSize = res.data.size;
          this.currentPage = res.data.current;
          this.total = res.data.total
        } else {
          this.code = res.code;
        }

      })
    },
    //充值订单列表分页
    handleSizeChangeA(val) {
      this.pagesize = val;
      this.queryOrListObj();
    },
    handleCurrentChangeA(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.queryOrListObj();
    },
    //购买订单列表
    queryBuyList() {
      let params = {
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      getParBuyListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.code = res.code;
          this.BuyListData = res.data.records
          this.pageSize = res.data.size;
          this.currentPage = res.data.current;
          this.total = res.data.total
        } else {
          this.code = res.code;
        }

      })
    },
    handleSizeChangeB(val) {
      this.pagesize = val;
      this.queryBuyList();
    },
    handleCurrentChangeB(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.queryBuyList();
    },
    //整本购买列表
    WholeByList() {
      let params = {
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      getParBookBuyListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        if(res.code==200) {
          this.code = res.code;
          this.WholeByListData = res.data.records
          this.pageSize = res.data.size;
          this.currentPage = res.data.current;
          this.total = res.data.total
        }else {
          this.code = res.code;
        }

      })
    },
    handleSizeChangeC(val) {
      this.pagesize = val;
      this.WholeByList();
    },
    handleCurrentChangeC(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.WholeByList();
    },
    //添加到购物车
    addCart() {
      // let params = {
      //   productId:this.id,
      //   selected:true,
      // }
      // carts(params).then((res) => { // eslint-disable-line no-unused-vars
      //   this.showModal = true;
      //   this.$store.dispatch('saveCartCount',res.data.cartTotal)
      // })
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }

  .tab-tilte{
    width: 96%;
    height: 36px;
    border-bottom: 1px solid #FF9100;
    margin: 10px auto;
    li{
      float: left;
      width: 25%;
      height: 35px;
      line-height: 35px;
      padding: 0px 0;
      text-align: center;
      margin-right: 10px;
      font-size: 15px;
      font-weight: bold;
      background-color:#FFF5E8;
      border: 1px solid #FF9100;
      cursor: pointer;
      border-radius: 6px 6px 0px 0px;
    }
    .active{
      background-color: #FF9100;
      color: #fff;
    }
  }
  .tab-content {
    width: 96%;
    margin: 10px auto;
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
